import { scan } from 'react-scan';
import { Queries } from '../../constants/url';

/**
 * launch react-scan library when scan=true query param is provided in url, work only on development mode
 * About CSR: Doesn't work on simulator, the csr project should be runned iframeless
 */
export const perfomReactScan = (): void => {
  try {
    const query = new URLSearchParams(window.location.search);
    const isScanEnabled = query.get(Queries.Scan) === 'true';

    if (isScanEnabled && process.env.NODE_ENV === 'development') {
      scan({ enabled: true });
    }
  } catch (error) {
    console.error('react-scan failed');
  }
};
