import { isSomeEnum } from '@dce-front/onewebapp-utils';

export const Build = {
  ES5: 'es5',
  ES6: 'es6',
} as const;

export type Build = (typeof Build)[keyof typeof Build];

export const isBuild = isSomeEnum(Build);

export const Es5BuildQuery = 'build=es5';
