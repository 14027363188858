import type { ApiV2Authenticate } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import {
  configuration,
  getConfigWithFilteredFeatureToggles,
  getMergedConfigWithAuthenticate,
} from '@dce-front/sdk-hodor';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { overrideSettingsWithCache } from '../../helpers/settings/settings-helper';
import {
  setApplicationConfigurationJson,
  setApplicationHodorSdkConfig,
} from '../../store/slices/application';
import {
  platformSelector,
  shouldDisplayWhoIsWatchingSelector,
} from '../../store/slices/application-selectors';
import { performAuthenticate } from './Authenticate/performAuthenticate';
import { getWhiteListedFeatureToggles } from './Init/getWhiteListedFeatureToggles';
import { performInit } from './Init/performInit';
import { setInitTracking } from './Init/setInitTracking/setInitTracking';
import { setUserDetails } from './Init/setUserDetails/setUserDetails';
import { getHodorSdkConfig } from './getSdkConfig';
import type { HodorCinematicParametersUniversal } from './types';

/** Server side (request is defined):
 *    1. Create an hodorSdkConfig
 *    2. Call configuration
 *    3. Perform init
 *    4. Perform authenticate calls
 *    5. Update the request with the hodorSdkConfig computed with init and authenticate responses
 *
 * Client side (request is undefined):
 *    1. Create an hodorSdkConfig
 *    2. Perform init
 *    3. Set user details. Add user's data in store and cookies
 *    4. Set init tracking
 *
 * Mode CSR ($_BUILD_RENDERMODE_CSR):
 *    1. Create an hodorSdkConfig
 *    2. Call configuration
 *    3. Perform init
 *    4. Perform authenticate calls
 *    5. Update the request with the hodorSdkConfig computed with init and authenticate responses
 *    6. Set user details. Add user's data in store and cookies
 *    7. Set init tracking
 */
export async function performHodorCinematicUniversal({
  store: argStore,
  proxyAgent,
  request,
  configVersionOverride,
}: HodorCinematicParametersUniversal): Promise<ApiV2Authenticate | undefined> {
  const xorStoreRequest = argStore ? { store: argStore } : { request };
  const xorStateRequest = argStore
    ? { dispatch: argStore.dispatch, state: argStore.getState() }
    : { request };

  let storeState = request
    ? request.reduxStore.store.getState()
    : argStore.getState();

  const {
    api: {
      hodor: { platforms },
    },
  } = getPublicConfig();

  const platform = platformSelector(storeState);

  if (platform === 'OrangeKey') {
    return;
  }

  const configVersion =
    configVersionOverride || platforms[platform].configVersion;

  const storeDispatch = request
    ? request.reduxStore.store.dispatch
    : argStore.dispatch;

  let hodorSdkConfig = getHodorSdkConfig({ proxyAgent, ...xorStateRequest });

  let authenticateResponse: ApiV2Authenticate | undefined;

  /** Call configuration if we are :
   * - in server side (request is defined)
   * - mode CSR
   */
  if (request || $_BUILD_RENDERMODE_CSR) {
    const configurationJsonData = await configuration(hodorSdkConfig, {
      version: configVersion,
    });

    if (request) {
      request.configurationJson = JSON.stringify(configurationJsonData);
    }

    storeDispatch(setApplicationConfigurationJson(configurationJsonData));

    // Override settings from cache for CSR
    if ($_BUILD_RENDERMODE_CSR && argStore) {
      overrideSettingsWithCache(argStore);
    }

    // Needs to updated store with configurationJson data inside
    storeState = request
      ? request.reduxStore.store.getState()
      : argStore.getState();
  }

  // Call performInit if all cases (server side and mode CSR)
  const { hodorSdkConfig: hodorSdkConfigFromInit, initResponse } =
    await performInit({
      config: hodorSdkConfig,
      ...xorStoreRequest,
    });

  hodorSdkConfig = hodorSdkConfigFromInit;

  const shouldDisplayWhoIsWatching =
    shouldDisplayWhoIsWatchingSelector(storeState);

  /** Call performAuthenticate if we are :
   * - in server side (request is defined)
   * - mode CSR
   */
  if (request || $_BUILD_RENDERMODE_CSR) {
    // If shouldDisplayWhoIsWatching and isFeatWhoIsWatching, not call hodor authenticate.
    // It will be called in clientSide when the user select his profile (see useProfileSelector)
    if (!shouldDisplayWhoIsWatching) {
      const perfomAuthenticateResult = await performAuthenticate({
        configWithInit: hodorSdkConfig,
        ...xorStoreRequest,
      });

      authenticateResponse = perfomAuthenticateResult.authenticateResponse;
      hodorSdkConfig = perfomAuthenticateResult.hodorSdkConfig;
    }

    if (request) {
      request.hodorSdkConfig =
        !shouldDisplayWhoIsWatching && authenticateResponse
          ? getMergedConfigWithAuthenticate(
              hodorSdkConfig,
              authenticateResponse,
            )
          : hodorSdkConfig;
    }
  }

  /** Case client side and CSR :
   * 1. Create an hodorSdkConfig
   * 3. Dispatch the hodorSdkConfig computed with init response in redux store
   * 4. Set user details
   * 5. Set init tracking
   *
   * If the **WhoIsWatching** feature isn't enabled, the **hodorSdkConfig** will be filtered with the whiteListedFeatureToggles\
   * Otherwise, the hodorSdkConfig will be filtered later in the `useProfileSelector` hook
   */
  if (!request) {
    let config = hodorSdkConfig;

    // If the **WhoIsWatching** feature isn't enabled, the **hodorSdkConfig** will be filtered with the whiteListedFeatureToggles\
    // Otherwise, the hodorSdkConfig will be filtered later in the `useProfileSelector` hook
    if (!shouldDisplayWhoIsWatching) {
      config = getConfigWithFilteredFeatureToggles({
        config: hodorSdkConfig,
        whiteListedFeatureToggles: getWhiteListedFeatureToggles(storeState),
      });
    }
    // Store sdk config in store here in case CSR
    storeDispatch(setApplicationHodorSdkConfig(config));

    // Set userDetails here in case CSR
    setUserDetails({
      store: argStore,
      init: initResponse,
    });
    setInitTracking({
      store: argStore,
      init: initResponse,
    });
  }

  return authenticateResponse;
}
