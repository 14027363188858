import { isSomeEnum } from '@dce-front/onewebapp-utils';
import {
  Environment,
  getOperatorFromOfferZone,
  OfferZone,
  Operator,
  Platform,
} from '@dce-front/sdk-core';
import { Queries } from '../../constants/url';

const CSREnvironment = {
  DEV: Environment.DEV,
  PROD: Environment.PROD,
  PREPROD: 'preprod',
  // FIXME : maybe added to sdk-core later on but would require to add some
  // endpoints in an oscure pass mapping
  MOCK: 'mock',
} as const;

export const getCsrConfigFile = (
  search: Location['search'],
): `${string}.json` => {
  const query = new URLSearchParams(search);

  const platformQuery = query.get(Queries.Platform);
  const platform =
    platformQuery && isSomeEnum(Platform)(platformQuery)
      ? platformQuery
      : undefined;

  const offerZoneQuery = query.get(Queries.OfferZone);
  const offerZone = isSomeEnum(OfferZone)(offerZoneQuery)
    ? offerZoneQuery
    : undefined;

  const environmentQuery = query.get(Queries.Environment);
  const environment =
    environmentQuery && isSomeEnum(CSREnvironment)(environmentQuery)
      ? environmentQuery
      : Environment.PROD;

  if (!offerZone) {
    throw new Error(`Invalid offerZone provided in URL "${offerZoneQuery}"`);
  }

  const isTim = getOperatorFromOfferZone(offerZone) === Operator.Tim;

  if (isTim) {
    return `static-${environment}-tim-generic.json`;
  }

  switch (platform) {
    case Platform.OrangeManhattan:
    case Platform.OrangeMib4:
    case Platform.OrangePlay:
    case Platform.OrangeStellar:
      return 'static-production-mycanal-orange.json';

    default: {
      return `static-${environment}-mycanal-generic.json`;
    }
  }
};
