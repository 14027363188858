import { isSomeEnum } from '@dce-front/onewebapp-utils';

export const SecondaryActionType = {
  Download: 'download',
  Playlist: 'playlist',
  Opinion: 'opinion',
  Share: 'share',
  Recording: 'recording',
  Authentication: 'authentication',
} as const;

export type SecondaryActionType =
  (typeof SecondaryActionType)[keyof typeof SecondaryActionType];

export const isSecondaryActionType = isSomeEnum(SecondaryActionType);
