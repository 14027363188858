import {
  addQueryParam,
  removeQueriesFromUrl,
} from '@dce-front/onewebapp-utils';
import { Template } from '@dce-front/sdk-hodor';
import createHistory from '../../createHistory';
import { extractEpgIDsFromContent } from '../../helpers/liveTV/liveTV-helper';
import { isImmersiveSelector } from '../slices/immersive-selectors';
import { pageDisplayTemplateSelector } from '../slices/page-selectors';
import { changeChannel, killPlayer, startPlayer } from '../slices/player';
import { routingLocationSelector } from '../slices/routing-selectors';

/**
 *  Change location search URL when needed by retrieving it in state
 */
export const transformUrlMiddleware: Redux.Middleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    const state = getState();
    const location = routingLocationSelector(state);
    const displayTemplate = pageDisplayTemplateSelector(state);
    const isImmersive = isImmersiveSelector(state);
    const isLiveContent =
      displayTemplate === Template.GabaritList && !isImmersive;
    const history = createHistory([]);

    if (killPlayer.match?.(action)) {
      if (isLiveContent && location) {
        const newSearch = removeQueriesFromUrl(location.search, ['channel']);
        history.replace({ ...location, search: newSearch });
      }

      return next(action);
    }

    if (startPlayer.match(action)) {
      const content = action.payload.settings?.content;

      if (content && isLiveContent && location) {
        const epgIDs = extractEpgIDsFromContent(content);
        if (epgIDs) {
          const newSearch = addQueryParam(
            location.search,
            'channel',
            epgIDs.toString(),
          );
          history.push({ ...location, search: newSearch });
        }
      }

      return next(action);
    }

    if (changeChannel.match(action)) {
      const { epgID } = action.payload;

      if (epgID && isLiveContent) {
        const newSearch = addQueryParam('', 'channel', epgID.toString());
        history.replace({ ...location, search: newSearch });
      }

      return next(action);
    }

    return next(action);
  };
