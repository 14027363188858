import type { RPCResponse } from '@canalplus/ifc';
import {
  DEFAULT_TIMEOUT_CALLBACK,
  initialize as initializeIfc,
  isInitialized,
  rpc,
} from '@canalplus/ifc';
import type { RPCOptions } from '@canalplus/ifc/dist/types';
import type {
  CreateRecordParams,
  ExitAppParams,
  ManageUserKeyPressParams,
  NavigateParams,
  OneCoreContext,
  OneCoreParentalCodeExist,
  OneCoreProfile,
  OneCoreSystem,
  ParsedUserData,
  RefreshProfileParams,
  SetAdDataParams,
  SetContextParams,
  SetExternalFrameVersionParams,
  SetTrackingDataParams,
  SetUserConfigParams,
  TrackSendParams,
  UserConfig,
  VideoSettings,
  ZapParams,
} from '@canalplus/onecore-types';
import { catchErrorTimeout } from './helpers/promiseErrors';
import { getKeysManager } from './keys';
import { getStreamsManager } from './streams';

// init keys manager
const { grabKey, releaseKey, onMessage: onMessageKeys } = getKeysManager();
// init streams manager
const {
  addStreamListener,
  removeStreamListener,
  onMessage: onMessageStreams,
} = getStreamsManager();

/**
 * Initialize the Ifc OneCore
 *
 * @param otherWindow A reference of the object window to communicate with
 * @param targetOrigin The targetOrigin of the window to communicate with (http + domain. Exemple : https://localhost).
 * @param timeout (optional default 10000 ms) Timeout in ms for all calls. After this time, the promise for a call rpc is rejected.
 */
export const initialize = (
  otherWindow: Window,
  targetOrigin: string,
  timeout: number = DEFAULT_TIMEOUT_CALLBACK,
): void => {
  // listen rpc message from ifc for keys and streams
  const onMessage = (data: RPCResponse) => {
    onMessageKeys(data);
    onMessageStreams(data);
  };

  // initialize the ifc
  initializeIfc(otherWindow, targetOrigin, timeout, onMessage);
};

export const getProfile = (options?: RPCOptions): Promise<OneCoreProfile> => {
  return rpc<undefined, OneCoreProfile>('getProfile', undefined, options);
};

export const getSystem = (options?: RPCOptions): Promise<OneCoreSystem> => {
  return rpc<undefined, OneCoreSystem>('getSystem', undefined, options);
};

export const setContext = (
  data: SetContextParams,
  options?: RPCOptions,
): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(
    rpc<SetContextParams, void>('setContext', data, options),
  );
};

export const refreshProfile = (
  data?: RefreshProfileParams,
  options?: RPCOptions,
): Promise<OneCoreProfile> => {
  return rpc<RefreshProfileParams, OneCoreProfile>(
    'refreshProfile',
    data,
    options,
  );
};

export const getContext = (options?: RPCOptions): Promise<OneCoreContext> => {
  return rpc<undefined, OneCoreContext>('getContext', undefined, options);
};

export const getDeviceKey = (options?: RPCOptions): Promise<OneCoreContext> => {
  return rpc<undefined, OneCoreContext>('getDeviceKey', undefined, options);
};

export const updateUserData = (
  data: Partial<ParsedUserData>,
  options?: RPCOptions,
): Promise<OneCoreProfile> => {
  return rpc<Partial<ParsedUserData>, OneCoreProfile>(
    'updateUserData',
    data,
    options,
  );
};

export const ready = (options?: RPCOptions): Promise<void> => {
  return rpc<undefined, void>('ready', undefined, options);
};

export const setUserConfig = (
  data: SetUserConfigParams,
  options?: RPCOptions,
): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(
    rpc<SetUserConfigParams, void>('setUserConfig', data, options),
  );
};

export const navigate = (
  data: NavigateParams,
  options?: RPCOptions,
): Promise<{ success: boolean }> => {
  return rpc<NavigateParams, { success: boolean }>('navigate', data, options);
};

export const trackSendPageView = (
  data: TrackSendParams,
  options?: RPCOptions,
): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(
    rpc<TrackSendParams, void>('trackSendPageView', data, options),
  );
};

export const trackSendEvent = (
  data: TrackSendParams,
  options?: RPCOptions,
): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(
    rpc<TrackSendParams, void>('trackSendEvent', data, options),
  );
};

export const loginUser = (options?: RPCOptions): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(
    rpc<undefined, void>('loginUser', undefined, options),
  );
};

export const logoutUser = (options?: RPCOptions): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(
    rpc<undefined, void>('logoutUser', undefined, options),
  );
};

export const manageUserKeyPress = (
  data: ManageUserKeyPressParams,
  options?: RPCOptions,
): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(
    rpc<ManageUserKeyPressParams, void>('manageUserKeyPress', data, options),
  );
};

export const exitApp = (
  data?: ExitAppParams,
  options?: RPCOptions,
): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(rpc<ExitAppParams, void>('exitApp', data, options));
};

export const parentalCodeExists = (
  options?: RPCOptions,
): Promise<OneCoreParentalCodeExist> => {
  return rpc<undefined, OneCoreParentalCodeExist>(
    'parentalCodeExists',
    undefined,
    options,
  );
};

export const zap = (data: ZapParams, options?: RPCOptions): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(rpc<ZapParams, void>('zap', data, options));
};

export const setExternalFrameVersion = (
  data: SetExternalFrameVersionParams,
  options?: RPCOptions,
): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(
    rpc<SetExternalFrameVersionParams, void>(
      'setExternalFrameVersion',
      data,
      options,
    ),
  );
};

export const createRecord = (
  data: CreateRecordParams,
  options?: RPCOptions,
): Promise<void> => {
  // @todo remove catchErrorTimeout when OneCore send a confirmation
  return catchErrorTimeout(
    rpc<CreateRecordParams, void>('createRecord', data, options),
  );
};

export const getVideoSettings = (
  options?: RPCOptions,
): Promise<VideoSettings> => {
  return rpc<undefined, VideoSettings>('getVideoSettings', undefined, options);
};

export const getUserConfig = (options?: RPCOptions): Promise<UserConfig> => {
  return rpc<undefined, UserConfig>('getUserConfig', undefined, options);
};

export const getMaxLiveQualityForCurrentBandwidth = (
  options?: RPCOptions,
): Promise<string> => {
  return rpc<undefined, string>(
    'getMaxLiveQualityForCurrentBandwidth',
    undefined,
    options,
  );
};

export const setTrackingData = (
  data: SetTrackingDataParams,
  options?: RPCOptions,
): Promise<void> => {
  return catchErrorTimeout(
    rpc<SetTrackingDataParams, void>('setTrackingData', data, options),
  );
};

export const setAdData = (
  data: SetAdDataParams,
  options?: RPCOptions,
): Promise<void> => {
  return catchErrorTimeout(
    rpc<SetAdDataParams, void>('setAdData', data, options),
  );
};

export const goBack = (options?: RPCOptions): Promise<void> => {
  return catchErrorTimeout(rpc<undefined, void>('goBack', undefined, options));
};

export {
  addStreamListener,
  grabKey,
  isInitialized,
  releaseKey,
  removeStreamListener,
};

export * from '@canalplus/onecore-types';
export { type GrabKeyResponse } from './types';
