import type { IAPIConfigRaw } from '@canalplus/oneplayer-types';
import { ThemeColor } from '../../constants/themeColor';
import { extractEpgIDsFromContent } from '../../helpers/liveTV/liveTV-helper';
import {
  sendPlayTrackingEvent,
  sendTrackingHitOnPage,
  sendTrackingHitOnSearch,
  sendTrackingOnCTAEvent,
} from '../../helpers/tracking/tracking-helper';
import {
  abTestingPopulationSelector,
  isKidsProfileSelector,
  segTypeSelector,
} from '../../store/slices/user-selectors';
import {
  DownloadToGoTypeKeys,
  type DownloadToGoActionTypes,
} from '../reducers/actions/DownloadToGo-actions';
import {
  updateSlideshowTracking,
  updateSlideshowTrackingContext,
} from '../slices/application';
import {
  offerLocationSelector,
  slideshowNotificationsTrackingSelector,
} from '../slices/application-selectors';
import { closeImmersive } from '../slices/immersive';
import { pageURLSelector } from '../slices/page-selectors';
import {
  destroyPlayer,
  openFullScreen,
  sendLaunchPlayerTracking,
} from '../slices/player';
import {
  infoTrackingSelector,
  playerSettingsSelector,
  playerTrackingContextSelector,
} from '../slices/player-selectors';
import { closeSearch } from '../slices/search';
import {
  searchQuerySelector,
  searchTrackingSelector,
} from '../slices/search-selectors';
import {
  closeImmersivePageTrackingSelector,
  findTrackingSelector,
} from '../slices/tracking-selectors';

/**
 * Handles all side effects for tracking
 */
export const trackingMiddleware: Redux.Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const state = getState();

    const abTestingPopulation = abTestingPopulationSelector(state);
    const isKids = isKidsProfileSelector(state);
    const offerLocation = offerLocationSelector(state);
    const pageURL = pageURLSelector(state);
    const segType = segTypeSelector(state);
    const tracking = findTrackingSelector(state);
    const closeImmersiveTracking = closeImmersivePageTrackingSelector(state);
    const searchTracking = searchTrackingSelector(state);

    if (sendLaunchPlayerTracking.match(action)) {
      const settings = playerSettingsSelector(state);
      const infoTracking = infoTrackingSelector(state);
      const trackingContext = playerTrackingContextSelector(state);
      const slideshowTracking = slideshowNotificationsTrackingSelector(state);

      const {
        data,
        isMultilive,
        isStartOverAsReplayAvailable,
        startFromBeginning,
      } = action.payload || {};

      const { ads } = data || {};
      const multiliveEpgIds = extractEpgIDsFromContent(
        data as IAPIConfigRaw['content'],
      );

      if (data) {
        sendPlayTrackingEvent({
          abTestingPopulation,
          infoTracking,
          isKids,
          isMultilive,
          isStartOverAsReplayAvailable,
          multiliveEpgIds,
          offerLocation,
          pageURL,
          platform: settings?.platform,
          playerAdsPath: ads?.path,
          segType,
          startFromBeginning,
          themeColor: ThemeColor.Dark,
          tracking,
          trackingContext,
          version: $_BUILD_APP_VERSION,
        });
      }

      // important reset tracking slideshow to have good context for next tracking
      if (slideshowTracking) {
        dispatch(updateSlideshowTracking(undefined));
        dispatch(updateSlideshowTrackingContext(undefined));
      }

      return next(action);
    }

    if (
      DownloadToGoTypeKeys.TRACK_OPEN_DOWNLOAD_TO_GO ===
      (action as DownloadToGoActionTypes).type
    ) {
      sendTrackingOnCTAEvent({
        abTestingPopulation,
        isKids,
        offerLocation,
        pageURL,
        segType,
        themeColor: ThemeColor.Dark,
        tracking,
        type: DownloadToGoTypeKeys.TRACK_OPEN_DOWNLOAD_TO_GO,
        version: $_BUILD_APP_VERSION,
      });

      return next(action);
    }

    if (openFullScreen.match(action)) {
      if ($_BUILD_RENDERMODE_CSR) {
        const settings = playerSettingsSelector(state);
        const infoTracking = infoTrackingSelector(state);
        const trackingContext = playerTrackingContextSelector(state);

        sendPlayTrackingEvent({
          abTestingPopulation,
          infoTracking,
          isKids,
          offerLocation,
          pageURL,
          platform: settings?.platform,
          segType,
          themeColor: ThemeColor.Dark,
          tracking,
          trackingContext,
          version: $_BUILD_APP_VERSION,
          launchPlayerType: 'replay',
        });
      }
      return next(action);
    }

    if (destroyPlayer.match(action)) {
      sendTrackingHitOnPage({
        abTestingPopulation,
        isKids,
        offerLocation,
        pageURL,
        segType,
        themeColor: ThemeColor.Dark,
        tracking,
        version: $_BUILD_APP_VERSION,
      });

      return next(action);
    }

    if (closeImmersive.match(action)) {
      sendTrackingHitOnPage({
        abTestingPopulation,
        isKids,
        offerLocation,
        pageURL,
        segType,
        themeColor: ThemeColor.Dark,
        tracking: closeImmersiveTracking,
        version: $_BUILD_APP_VERSION,
      });

      return next(action);
    }

    if (closeSearch.match(action)) {
      const query = searchQuerySelector(state);
      // send tracking event when search bar closes only when it's not empty
      if (query) {
        sendTrackingHitOnSearch({
          abTestingPopulation,
          isKids,
          offerLocation,
          pageURL,
          segType,
          themeColor: ThemeColor.Dark,
          tracking: searchTracking || {},
          version: $_BUILD_APP_VERSION,
          searchContentOpened: false,
        });
      }

      return next(action);
    }

    return next(action);
  };
