import type { OfferLocation } from '@dce-front/sdk-core';
import { uniq } from 'es-toolkit';
import { LANGUAGES, type Locale } from '../../constants/lang';
import type { OneDiscoveryTranslations } from '../../lang/types';
import { getPublicConfig } from '../config/config-helper';

const localeCache = new Map<string, OneDiscoveryTranslations>();

// On the server, we pre-populate the localeCache by reading from the filesystem
// This way everything is accessible via getLocalTranslations synchronously
if (IS_SERVER) {
  const locales = uniq(Object.values(LANGUAGES));

  locales.forEach((locale: string) => {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    localeCache.set(locale, require(`../../lang/${locale}`).default);
  });
}

/**
 * Load a client locale file.
 * @param locale the locale to load
 */
export async function loadClientTranslations(locale: Locale): Promise<void> {
  const { default: translations } = await import(
    `../../lang/${LANGUAGES[locale] || 'fr-fr'}`
  );

  localeCache.set(locale, translations);
}

export function getLocaleWithFallback(
  locale: Locale,
): (typeof LANGUAGES)[keyof typeof LANGUAGES] {
  const { langKey, offerLocation } = getPublicConfig().defaultLocale;
  const defaultLocale = `${langKey}-${offerLocation}` as Locale;

  return LANGUAGES[locale] || LANGUAGES[defaultLocale] || 'fr-fr';
}

/**
 * Get content language for httpEquiv
 * @returns full locale or fr-FX if the location is Metropolitan France
 */
export function getContentLanguage(
  lang: string,
  offerLocation: OfferLocation,
): string {
  let fullLocale = `${lang}-${offerLocation.toUpperCase()}`;
  if (fullLocale === 'fr-FR') {
    fullLocale = 'fr-FX';
  }

  return fullLocale;
}

/**
 * Synchronously retrieve the locale file
 * @param locale the locale to load
 * @returns the locale file
 *
 * @throws On the client, throw if the needed translation file was not loaded via `loadClientTranslations`
 *
 * @see loadClientTranslations
 */
export const getLocalTranslations = (
  locale: Locale,
): OneDiscoveryTranslations => {
  if (IS_SERVER) {
    return localeCache.get(
      getLocaleWithFallback(locale),
    ) as OneDiscoveryTranslations; // || localeCache.get(defaultLocale) || (localeCache.get('fr-fr') as OneDiscoveryTranslations)
  } else {
    const translations = localeCache.get(locale);

    if (!translations) {
      throw new Error('Expected translations to be loaded');
    }

    return translations;
  }
};
