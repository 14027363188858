export const ModalTypes = {
  EXTERNAL_SERVICE_DOWNLOAD_MODAL: 'EXTERNAL_SERVICE_DOWNLOAD_MODAL',
  EXTERNAL_SERVICE_STREAM_MODAL: 'EXTERNAL_SERVICE_STREAM_MODAL',
  GENERIC_MODAL: 'GENERIC_MODAL',
  MOBILE_APP_DOWNLOAD: 'MOBILE_APP_DOWNLOAD',
  STARTOVER_MODAL: 'STARTOVER_MODAL',
  ZONES_LIST_MODAL: 'ZONES_LIST_MODAL',
} as const;

export type ModalTypes = (typeof ModalTypes)[keyof typeof ModalTypes];
