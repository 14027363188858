import { isSomeEnum } from '@dce-front/onewebapp-utils';

export const Url = {
  Home: '/',
  HomePL: '/pl/',
  HomeCH: '/ch/',
} as const;

export type Url = (typeof Url)[keyof typeof Url];

export const AccountUrl = {
  DeleteAccount: 'moncompte/delete',
  ParentalCode: 'moncompte/code/parental',
  PurchaseCode: 'moncompte/code/purchase',
  PurchaseCodeDeletion: 'moncompte/code/purchase/delete',
  ViolentContent: 'moncompte/code/parental/violentcontent',
} as const;

export type AccountUrl = (typeof AccountUrl)[keyof typeof AccountUrl];

/** @public */
export const isAccountUrl = isSomeEnum(AccountUrl);

/**
 * Representation of supported queries values for buffer entry page
 */
export const BufferQueries = {
  AppKey: 'appkey',
  ConfigVersion: 'configversion',
  Display: 'display',
  Environment: 'environment',
  ForceWhoIsWatching: 'forcewiw',
  Logger: 'logger',
  MenuVersion: 'menuversion',
  Mock: 'mock',
  Scan: 'scan',
  Path: 'path',
  Resize: 'resize',
  UpdateRights: 'updateRights',
} as const;

export type BufferQueries = (typeof BufferQueries)[keyof typeof BufferQueries];

export const Queries = {
  ...BufferQueries,
  Arbo: 'arbo',
  Buffer: 'buffer',
  Build: 'build',
  CallbackState: 'callbackState',
  Channel: 'channel',
  Code: 'code',
  Devtools: 'devtools',
  DisplayGDPR: 'displayGDPR',
  FeatureToggles: 'featureToggles',
  InitialHistoryEntries: 'history',
  OfferLocation: 'offerLocation',
  OfferZone: 'offerZone',
  PassAuthSuccessCallback: 'passAuthSuccessCallback',
  Platform: 'platform',
  RedirectUri: 'redirect_uri',
  RefreshRight: 'refreshRight',
  Token: 'token',
  VoucherKey: 'voucherKey',
} as const;

export type Queries = (typeof Queries)[keyof typeof Queries];
