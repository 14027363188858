import { ThemeColor } from '../constants/themeColor';

export const ApplicationTheme = {
  Default: 'default',
  Tvod: 'tvod',
  Tim: 'telecomitalia',
} as const;

export type ApplicationTheme =
  (typeof ApplicationTheme)[keyof typeof ApplicationTheme];

export const Dark = ThemeColor.Dark;
