export const ADDED_TO_PLAYLIST = 'addedToPlaylist';
export const REMOVED_FROM_PLAYLIST = 'removedFromPlaylist';

export const PlaylistStateId = {
  NotInPlaylist: 'notInPlaylist',
  InPlaylist: 'inPlaylist',
} as const;

export type PlaylistStateId =
  (typeof PlaylistStateId)[keyof typeof PlaylistStateId];
