/* eslint-disable @typescript-eslint/no-require-imports */
import {
  OfferZone,
  getZoneParameters,
  isOfferLocation,
} from '@dce-front/sdk-core';
import type { Request } from 'express';
import type { ConfigType, Public } from '../../../config/application/types';
import type { ZoneInfo } from '../../store/slices/application-types';
import { getCsrConfigFile } from './getCsrConfigFile';

export const loadCSRConfig = async (): Promise<void> => {
  const configFile = getCsrConfigFile(window.location.search);

  (window as any).app_config = await import(
    `../../../config/transpiled/${configFile}`
  );
};

/** Return the whole public configuration */
export const getPublicConfig = (): Public => {
  // On server-side, retrieving the client config from the node config
  if (IS_SERVER) {
    // Conditional require is mandatory since only node server can handle it
    return require('config').get('public');
  }

  // On client-side, the config is already loaded in window
  return (window as any).app_config;
};

/** Return the whole server configuration */
export const getServerConfig = (): ConfigType => {
  if (IS_SERVER) {
    // Conditional require is mandatory since only node server can handle it
    return require('config');
  }

  throw new Error(
    'Server config is only available in server execution context.',
  );
};

/**
 * Get the locale data from the path and the query
 * `isTvDevice`: boolean
 * `path`: string
 * `offerLocationQuery: string | null
 * @returns Locale
 */
export const getLocaleData = (
  isTvDevice: boolean,
  path: string,
  offerLocationQuery?: Request['query'] | string | null,
  offerZoneQuery?: Request['query'] | string | null,
): ZoneInfo => {
  const { operator, defaultLocale, zones } = getPublicConfig();

  const [, splittedOfferLocation] = path.split('/');

  const offerLocationFromPath = isOfferLocation(splittedOfferLocation)
    ? splittedOfferLocation
    : defaultLocale.offerLocation;

  const offerLocationTv =
    offerLocationQuery && isOfferLocation(offerLocationQuery)
      ? offerLocationQuery
      : defaultLocale.offerLocation;

  const { offerLocation, lang, offerZone, appKey } = getZoneParameters({
    offerLocation: isTvDevice ? offerLocationTv : offerLocationFromPath,
    // Temporary dev for german-speaking switzerland (waiting for the multi-language)
    lang:
      path.includes('/ch/de') || offerZoneQuery === OfferZone.cpchd
        ? 'de'
        : defaultLocale.langKey,
    zones,
    operator,
  });

  return { offerLocation, lang, offerZone, appKey };
};
