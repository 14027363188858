export const CookieKey = {
  AbTestingPopulation: 'abTestingPopulation',

  /** TrackingTool ** */
  AdsAllowed: 'adsAllowed',
  AnonymousAllowed: 'anonymousAllowed',
  StatsAllowed: 'statsAllowed',

  /** deviceId shape: **base:isAuthenticated:trackingKeyId** */
  DeviceId: 'deviceId',

  HodorKey: 'hodorKey',

  /** Stored as a string */
  IsKidsProfile: 'isKidsProfile',

  Notification: 'notification',
  Offerzone: 'offerzone',
  PassId: 'passId',

  /** Legacy passId */
  PPassToken: 'p_pass_token',

  /** Stored as a string */
  ProfileId: 'profileId',

  SessionId: 'sessionId',
  Recommandations: 'recommandations',
  TokenCMS: 'tokenCMS',
  WhoIsWatchingDisabled: 'whoIsWatchingDisabled',
  WhoIsWatchingSession: 'whoIsWatchingSession',
} as const;

export type CookieKey = (typeof CookieKey)[keyof typeof CookieKey];

/** Duration in days for the deviceId cookie is 395 days = 13 months */
export const DEVICE_ID_COOKIE_DURATION = 395;
