import { setInitialLocationHref } from '../helpers/ifcOneCore/ifc-onecore-helpers';
import { perfomReactScan } from '../helpers/scan/scan-helper';
import client from './client';

perfomReactScan();

// Save initial location href (for debug menu)
setInitialLocationHref(window.location.href);

client();
