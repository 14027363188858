export const SettingQualityD2G = {
  Medium: 'SD',
  High: 'HD',
  default: 'default',
} as const;

export type SettingQualityD2G =
  (typeof SettingQualityD2G)[keyof typeof SettingQualityD2G];

export type D2GModalProps = {
  contentId?: string;
  idItem?: string;
  availabilityEndDate?: number;
  contentPathname?: string;
  downloadDate?: number;
  title?: string;
};
