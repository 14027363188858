import { fillTemplate } from '@dce-front/onewebapp-utils';
import type { OfferLocation, OfferZone } from '@dce-front/sdk-core';

type GetFilledPathParameters = {
  offerLocation: OfferLocation;
  offerZone: OfferZone;
  path: `/${string}`;
};

/** Fills the zone path template with the given offerLocation and offerZone */
export const getFilledPath = ({
  path,
  offerLocation,
  offerZone,
}: GetFilledPathParameters): `/${string}` =>
  fillTemplate(path, [['zone', `${offerZone}-${offerLocation}`]]);
