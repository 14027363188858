import type { ServicesKeys } from '@canalplus/ifc-onecore';
import {
  isInitialized as isIfcInitialized,
  navigate,
} from '@canalplus/ifc-onecore';
import type { ApiV2GetMediaUrlPage } from '@dce-front/hodor-types';
import type { DataLayerTracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import type { DeeplinkDetailInformation } from '@dce-front/hodor-types/api/v2/me/get_media_url/definitions';
import type { ApiV2PageTrackingDataLayer } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import type { DeeplinkApps } from '@dce-front/hodor-types/modules/get_media_url/definitions';
import { fillTemplate } from '@dce-front/onewebapp-utils';
import type { OfferLocation, Platform } from '@dce-front/sdk-core';
import {
  HapiOs,
  HapiOsByPlatformMap,
  PassPlatform,
  PlatformGroup,
} from '@dce-front/sdk-core';
import { requestPage } from '@dce-front/sdk-hodor';
import type { FetchOptions } from '@dce-front/util-fetch';
import { apiCallWithTokenpassRenewal } from '../../api/PersoApi/PersoApi';
import type { ApiCallOptionParameters } from '../../api/types';
import {
  DEEPLINK,
  PLAY_DEEPLINK,
  START_DOWNLOAD_DEEPLINK,
} from '../../constants/tracking';
import { getPublicConfig } from '../../helpers/config/config-helper';
import Logger from '../../helpers/logger/logger-helper';
import {
  sendDeeplinkTrackingEvent,
  type SendDeeplinkTrackingEventOptions,
} from '../../helpers/tracking/tracking-helper';
import {
  fillUrlWithPassId,
  getUrlWithTokenCMS,
  isUrlContainsPassId,
} from '../../helpers/url/url-helper';
import type { AllActionsTypes } from '../reducers/actions/All-actions-types';
import {
  ActionExternalServicesTypeKeys,
  type IHandleLaunchExternalServiceAction,
  type ILaunchExternalServiceAction,
} from '../reducers/actions/ExternalServices-actions';
import {
  getFeatureExternalServicesSelector,
  hodorSdkConfigSelector,
  offerLocationSelector,
  platformGroupSelector,
  platformSelector,
  tokenCMSSelector,
} from '../slices/application-selectors';
import { pageURLSelector } from '../slices/page-selectors';
import {
  effectiveTrackingContextSelector,
  findTrackingSelector,
} from '../slices/tracking-selectors';
import {
  abTestingPopulationSelector,
  isKidsProfileSelector,
  segTypeSelector,
} from '../slices/user-selectors';

const { LAUNCH_EXTERNAL_SERVICE, HANDLE_LAUNCH_EXTERNAL_SERVICE } =
  ActionExternalServicesTypeKeys;

/**
 * Launch external service web from detail URLMedias
 * @param detail
 * @param dataTracking
 */
const launchDetailExternalServiceWeb = (
  detail: ApiV2GetMediaUrlPage['detail'] | undefined,
  dataTracking: SendDeeplinkTrackingEventOptions,
) => {
  // Get external service url from URLMedias response
  const externalUrl = (detail?.informations as DeeplinkDetailInformation)
    ?.deeplink?.data?.url;

  if (externalUrl) {
    sendDeeplinkTrackingEvent(dataTracking);
    window.open(externalUrl, '_blank');
  } else {
    Logger.error(
      `ExternalService::launchExternalService() - Error occurs during launchExternalService. No externalUrl found`,
    );
  }
};

/**
 * Launch external service web
 * @param target
 * @param platform
 * @param offerLocation
 * @param URLWebsite
 * @param serviceID
 */
const launchExternalServiceWeb = ({
  target,
  platform,
  offerLocation,
  URLWebsite,
  serviceID,
}: {
  target: string;
  platform: Platform;
  offerLocation: OfferLocation;
  serviceID?: string;
  URLWebsite?: string;
}) => {
  if (serviceID === 'LAUNCH_PRIVACY_MANAGER' && window.Didomi) {
    window.Didomi.notice.show();
  }

  if (URLWebsite) {
    let urlToOpen = URLWebsite;

    if (isUrlContainsPassId(URLWebsite)) {
      urlToOpen = fillUrlWithPassId(urlToOpen);
    }

    urlToOpen = fillTemplate(urlToOpen, [
      ['media', PassPlatform[platform]],
      ['appLocation', offerLocation],
    ]);

    window.open(urlToOpen, target);
  } else {
    Logger.error(
      `ExternalService::launchExternalService() - Error occurs during launchExternalServiceWeb.`,
    );
  }
};

// Todo: ApiV2GetMediaUrlPage['detail'] need to be fixed with right types on HODOR side
type DetailDeeplinkApps = ApiV2GetMediaUrlPage['detail'] & {
  informations: DeeplinkDetailInformation & {
    deeplink: {
      data: {
        apps: DeeplinkApps &
          Record<HapiOs, { serviceID: ServicesKeys; deeplink?: string }>;
      };
    };
  };
};

/**
 * Launch external service tv from detail URLMedias
 * @param detail
 * @param dataTracking
 * @param platform
 */
const launchDetailExternalServiceTv = (
  detail: DetailDeeplinkApps,
  dataTracking: SendDeeplinkTrackingEventOptions,
  platform: Platform,
) => {
  if (!isIfcInitialized()) {
    Logger.error(
      `ExternalService::launchExternalService() - Error occurs during launchExternalService. Api Ifc is not available.`,
    );
    return;
  }

  const deepLinkApps = detail.informations.deeplink.data.apps;

  const targetApp =
    deepLinkApps[HapiOsByPlatformMap[platform]] || deepLinkApps[HapiOs.Default];

  if (!targetApp.serviceID) {
    Logger.error(
      `ExternalService::launchExternalService() - Error occurs during launchExternalService. No serviceID found.`,
    );
    return;
  }
  const { serviceID, deeplink: contentID } = targetApp;

  sendDeeplinkTrackingEvent(dataTracking);

  navigate({
    skey: serviceID,
    context: contentID ? { deeplink: { contentId: contentID } } : undefined,
  }).catch(console.error);
};

/**
 * Launch external service tv from detail for Orange Boxes URLMedias
 * @param detail
 * @param dataTracking
 * @param platform
 */
const launchDetailExternalServiceOrangeTv = (
  detail: ApiV2GetMediaUrlPage['detail'] | undefined,
  dataTracking: SendDeeplinkTrackingEventOptions,
) => {
  if (isIfcInitialized()) {
    const deepLinkParams =
      detail && 'deeplink' in detail.informations
        ? detail.informations.deeplink?.data?.params
        : undefined;

    const serviceID = deepLinkParams
      ? deepLinkParams.find((item) => item.field?.toLowerCase() === 'serviceid')
          ?.value
      : undefined;

    if (serviceID) {
      sendDeeplinkTrackingEvent(dataTracking);

      const contentID = deepLinkParams
        ? deepLinkParams.find(
            (item) => item.field?.toLowerCase() === 'contentid',
          )?.value
        : undefined;

      navigate({
        skey: serviceID as ServicesKeys,
        context: contentID ? { deeplink: { contentId: contentID } } : undefined,
      }).catch(console.error);
    } else {
      Logger.error(
        `ExternalService::launchExternalService() - Error occurs during launchExternalService. No serviceID found.`,
      );
    }
  } else {
    Logger.error(
      `ExternalService::launchExternalService() - Error occurs during launchExternalService. Api Ifc is not available.`,
    );
  }
};

/**
 * Launch external service tv
 * @param contentID
 * @param serviceID
 */
const launchExternalServiceTV = ({
  contentID,
  serviceID,
}: {
  contentID?: string;
  serviceID?: string;
}) => {
  if (serviceID && $_BUILD_RENDERMODE_CSR) {
    navigate({
      skey: serviceID as ServicesKeys,
      context: contentID ? { deeplink: { contentId: contentID } } : undefined,
    }).catch(console.error);
  }
};

export const externalServicesMiddleware: Redux.Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    const state = getState();

    switch ((action as AllActionsTypes).type) {
      case LAUNCH_EXTERNAL_SERVICE:
        try {
          const featExternalServices =
            getFeatureExternalServicesSelector(state);
          const tokenCMS = tokenCMSSelector(state);

          if (!featExternalServices || !tokenCMS) {
            break;
          }

          const { URLMedias, isDownload } = (
            action as ILaunchExternalServiceAction
          ).payload;

          // Call URLMedias to get external service url (stream or download)
          const urlWithTokenCMS = getUrlWithTokenCMS(URLMedias, tokenCMS);

          const apiCall = (options: ApiCallOptionParameters) => {
            const newOptions: FetchOptions = {
              headers: {
                ...options.headers,
                ...(options.headers.passtoken && {
                  passtoken: options.headers.tokenPass,
                }),
              },
              timeout: getPublicConfig().api.paymentProvider.timeout.default,
            };

            // Delete unauthorized headers to avoid potential cors errors
            if (newOptions.headers && 'passtoken' in newOptions.headers) {
              delete newOptions.headers.passtoken;
            }

            return fetch(urlWithTokenCMS, newOptions);
          };
          const response = await dispatch(
            apiCallWithTokenpassRenewal(apiCall as any),
          );

          if (!response) {
            throw new Error('launchExternalService failed to fetch');
          }

          const json: ApiV2GetMediaUrlPage = await response.json();
          const { detail, tracking } = json || {};

          const dataLayer = tracking?.dataLayer || {};
          const context = effectiveTrackingContextSelector(state);
          const trackingWithContext = { ...dataLayer, ...context };
          const trackingName = isDownload
            ? START_DOWNLOAD_DEEPLINK
            : PLAY_DEEPLINK;
          const abTestingPopulation = abTestingPopulationSelector(state);
          const segType = segTypeSelector(state);
          const pageURL = pageURLSelector(state);
          const offerLocation = offerLocationSelector(state);
          const isKids = isKidsProfileSelector(state);
          const platformGroup = platformGroupSelector(state);
          const platform = platformSelector(state);
          const orangePlatform = platformGroup === PlatformGroup.Orange;

          const dataTracking: SendDeeplinkTrackingEventOptions = {
            tracking: trackingWithContext,
            name: trackingName,
            abTestingPopulation,
            segType,
            version: $_BUILD_APP_VERSION,
            pageURL,
            offerLocation,
            isKids,
          };

          if (!$_BUILD_RENDERMODE_CSR) {
            launchDetailExternalServiceWeb(detail, dataTracking);
          } else if (orangePlatform) {
            launchDetailExternalServiceOrangeTv(detail, dataTracking);
          } else {
            launchDetailExternalServiceTv(
              detail as DetailDeeplinkApps,
              dataTracking,
              platform,
            );
          }
        } catch (error) {
          Logger.error(
            `ExternalService::launchExternalService() - Error occurs during launchExternalService ${error}`,
          );
        }
        break;

      case HANDLE_LAUNCH_EXTERNAL_SERVICE: {
        let tracking:
          | DataLayerTracking
          | ApiV2PageTrackingDataLayer
          | undefined;
        const {
          URLPage,
          contentID,
          URLWebsite,
          trackingContext,
          serviceID,
          platform,
          target,
        } = (action as IHandleLaunchExternalServiceAction).payload;

        const context = effectiveTrackingContextSelector(state);
        const abTestingPopulation = abTestingPopulationSelector(state);
        const segType = segTypeSelector(state);
        const pageURL = pageURLSelector(state);
        const offerLocation = offerLocationSelector(state);
        const isKids = isKidsProfileSelector(state);
        const hodorSdkConfig = hodorSdkConfigSelector(state);

        try {
          if (URLPage && hodorSdkConfig) {
            // Call URLPage to get tracking object
            const response = (await requestPage(hodorSdkConfig, {
              url: URLPage,
            })) as any;

            tracking = response?.tracking?.dataLayer;
          } else {
            tracking = findTrackingSelector(state).dataLayer;
          }
          const trackingWithContext = {
            ...tracking,
            ...context,
            ...trackingContext,
          };

          // Handle tracking for external site context
          if (trackingWithContext) {
            sendDeeplinkTrackingEvent({
              tracking: trackingWithContext,
              name: DEEPLINK,
              abTestingPopulation,
              segType,
              version: $_BUILD_APP_VERSION,
              pageURL,
              offerLocation,
              isKids,
              ...(contentID && { contentID }),
              ...(URLWebsite && { urlWebsite: URLWebsite }),
            });
          }
        } catch (error) {
          Logger.error(
            `ExternalService::launchExternalService() - Error occurs during launchExternalService ${error}`,
          );
        }

        if ($_BUILD_RENDERMODE_CSR) {
          launchExternalServiceTV({
            contentID,
            serviceID,
          });
        } else {
          launchExternalServiceWeb({
            target,
            platform,
            offerLocation,
            URLWebsite,
            serviceID,
          });
        }

        break;
      }
      default:
        break;
    }

    return next(action);
  };
