import type { ApiV2InitResponse } from '@dce-front/hodor-types';
import {
  getMergedConfigWithInit,
  type HodorSdkConfig,
} from '@dce-front/sdk-hodor';
import type { Request } from 'express';
import type { XOR } from 'ts-essentials';
import { markPerformance } from '../../../helpers/performance/markPerformance';
import { fetchInit } from './fetchInit';
import { setInitTracking } from './setInitTracking/setInitTracking';

export type PerformInitParameters = {
  config: HodorSdkConfig;
} & XOR<{ request: Request }, { store: Redux.CustomStore }>;

/**
 * Fetch init Hodor API
 *
 * - return a merged `HodorSdkConfig` with init response
 * - throw an error if init response doesn't contain **URLAuthenticate**
 */
export async function performInit({
  config,
  request,
  store: argStore,
}: PerformInitParameters): Promise<{
  initResponse: ApiV2InitResponse;
  hodorSdkConfig: HodorSdkConfig;
}> {
  const store = argStore || request.reduxStore.store;
  const state = store.getState();
  const initResponse = await fetchInit({ config, state });

  markPerformance('hodor-init-fetched', state);

  if (typeof initResponse.URLAuthenticate !== 'string') {
    throw new Error('Expect initResponse.URLAuthenticate to be defined.');
  }

  setInitTracking({ store, init: initResponse });

  return {
    initResponse,
    hodorSdkConfig: getMergedConfigWithInit(config, initResponse),
  };
}
