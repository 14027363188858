export const DownloadToGoTypeKeys = {
  TRACK_OPEN_DOWNLOAD_TO_GO: 'OPEN_DOWNLOAD_TO_GO',
} as const;

export type DownloadToGoTypeKeys =
  (typeof DownloadToGoTypeKeys)[keyof typeof DownloadToGoTypeKeys];

export interface ID2GTrackOpenModal {
  readonly type: typeof DownloadToGoTypeKeys.TRACK_OPEN_DOWNLOAD_TO_GO;
}
export const trackOpenDownloadToGo = (): ID2GTrackOpenModal => ({
  type: DownloadToGoTypeKeys.TRACK_OPEN_DOWNLOAD_TO_GO,
});

export type DownloadToGoActionTypes = ID2GTrackOpenModal;
