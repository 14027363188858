import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import type { ApiV2InitDataLayer } from '@dce-front/hodor-types/api/v2/me/init/definitions';
import type { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { OfferLocation } from '@dce-front/sdk-core';
import type { ThemeColor } from '../../constants/themeColor';
import type { PurchaseCodeState } from '../../store/slices/purchaseCode';

export interface ICTATracking {
  eVar33?: string;
  events?: string;
}

export interface IPlayInfoTracking {
  isTrailer?: boolean;
  title?: string;
  subtitle?: string;
  isNoPub?: boolean;
  duration?: string;
  videoPathPub?: string;
}

export const TealiumKey = {
  poland: 'poland-main',
} as const;

export type TealiumKey = (typeof TealiumKey)[keyof typeof TealiumKey];

export const TealiumKeySuffix = {
  africa: 'africa',
  caraibes: 'caraibes',
  reunion: 'reunion',
  caledonie: 'caledonie',
  madagascar: 'madagascar',
  maurice: 'mauritius',
  suisse: 'suisse',
} as const;

export type TealiumKeySuffix =
  (typeof TealiumKeySuffix)[keyof typeof TealiumKeySuffix];

export const TealiumPrefix = {
  tvod: 'tvod',
} as const;

export type TealiumPrefix = (typeof TealiumPrefix)[keyof typeof TealiumPrefix];

export const tealiumZones: {
  [T in TealiumKeySuffix]: OfferLocation[];
} = {
  [TealiumKeySuffix.africa]: [
    OfferLocation.bf,
    OfferLocation.bi,
    OfferLocation.bj,
    OfferLocation.cg,
    OfferLocation.ci,
    OfferLocation.cm,
    OfferLocation.cv,
    OfferLocation.dj,
    OfferLocation.et,
    OfferLocation.ga,
    OfferLocation.gh,
    OfferLocation.gm,
    OfferLocation.gn,
    OfferLocation.gq,
    OfferLocation.gw,
    OfferLocation.ml,
    OfferLocation.mr,
    OfferLocation.ne,
    OfferLocation.cd,
    OfferLocation.cf,
    OfferLocation.rw,
    OfferLocation.sl,
    OfferLocation.sn,
    OfferLocation.td,
    OfferLocation.tg,
  ],
  [TealiumKeySuffix.caraibes]: [
    OfferLocation.gp,
    OfferLocation.gf,
    OfferLocation.mq,
    OfferLocation.bl,
    OfferLocation.mf,
  ],
  [TealiumKeySuffix.reunion]: [OfferLocation.re, OfferLocation.yt],
  [TealiumKeySuffix.caledonie]: [
    OfferLocation.nc,
    OfferLocation.wf,
    OfferLocation.pf,
  ],
  [TealiumKeySuffix.madagascar]: [OfferLocation.mg],
  [TealiumKeySuffix.maurice]: [OfferLocation.mu],
  [TealiumKeySuffix.suisse]: [OfferLocation.ch],
};

export type RawTracking = {
  isKids: boolean;
  tracking: Tracking | ApiV2PageTracking;
  initTracking?: ApiV2InitDataLayer;
  abTestingPopulation?: number;
  CTATrackingObj?: ICTATracking;
  error?: string;
  favoritesEpgIds?: number[];
  offerLocation?: OfferLocation;
  pageURL?: string;
  resultsLength?: number;
  searchContentOpened?: boolean;
  segType?: string | undefined;
  themeColor?: ThemeColor;
  trackingContext?: ApiV2Context;
  version?: string | undefined;
  purchaseInfo?: PurchaseCodeState['purchaseInfo'];
};

export type AvatarTrackingData = {
  avatarId?: number;
  avatarName?: string;
  avatarListName?: string;
};
