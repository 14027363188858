import type { ApiV2InitResponse } from '@dce-front/hodor-types';
import type { HodorSdkConfig } from '@dce-front/sdk-hodor';
import { init } from '@dce-front/sdk-hodor';
import {
  hasUserDataCollectedSelector,
  profilesUpdateDateSelector,
} from '../../../store/slices/user-selectors';
import type { IState } from '../../../store/types/State-type';
import { getSdkInitParameters } from './getSdkInitParameters';

type FetchInitParameters = {
  config: HodorSdkConfig;
  state: IState;
};

export async function fetchInit({
  config,
  state,
}: FetchInitParameters): Promise<ApiV2InitResponse> {
  const profilesUpdateDate = profilesUpdateDateSelector(state);
  const hasUserDataCollected = hasUserDataCollectedSelector(state);

  const initResponse = await init(
    config,
    getSdkInitParameters(profilesUpdateDate, hasUserDataCollected),
  );

  return initResponse;
}
