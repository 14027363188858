export const WITH_AD = 'content with ads';
export const WITHOUT_AD = 'content without ads';
export const PLAY_DEEPLINK = 'play deeplink';
export const DEEPLINK = 'deeplink';
export const START_DOWNLOAD_DEEPLINK = 'start download deeplink';

export const ProfileEvents = {
  Create: 'create',
  Edit: 'edit',
  Change: 'change',
  Delete: 'delete',
  Select: 'select',
} as const;

export type ProfileEvents = (typeof ProfileEvents)[keyof typeof ProfileEvents];

export const ProfileType = {
  Standard: 'standard',
  Kids: 'kids',
} as const;

export type ProfileType = (typeof ProfileType)[keyof typeof ProfileType];

export const SettingImageQualityValue = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High',
} as const;

export type SettingImageQualityValue =
  (typeof SettingImageQualityValue)[keyof typeof SettingImageQualityValue];

export const TrackingEventName = {
  ChangeSetting: 'change setting',
  ViewError: 'view error',
  ViewAlert: 'view alert',
  CloseAlert: 'close alert',
} as const;

export type TrackingEventName =
  (typeof TrackingEventName)[keyof typeof TrackingEventName];

export const CHANGE_SETTING_IMAGE_QUALITY_NAME = 'ImageQuality';
