import { isSomeEnum } from '@dce-front/onewebapp-utils';

export const Moods = {
  Dislike: 'dislike',
  Like: 'like',
  Neutral: 'neutral',
  NoOpinion: 'noopinion',
} as const;

export type Moods = (typeof Moods)[keyof typeof Moods];

export const isMoods = isSomeEnum(Moods);
