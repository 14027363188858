import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { OfferLocation, Platform } from '@dce-front/sdk-core';

export const ActionExternalServicesTypeKeys = {
  LAUNCH_EXTERNAL_SERVICE: 'LAUNCH_EXTERNAL_SERVICE',
  HANDLE_LAUNCH_EXTERNAL_SERVICE: 'HANDLE_LAUNCH_EXTERNAL_SERVICE',
} as const;

export type ActionExternalServicesTypeKeys =
  (typeof ActionExternalServicesTypeKeys)[keyof typeof ActionExternalServicesTypeKeys];

export interface ILaunchExternalServiceAction {
  readonly type: typeof ActionExternalServicesTypeKeys.LAUNCH_EXTERNAL_SERVICE;
  readonly payload: { URLMedias: string; isDownload: boolean };
}

export const launchExternalService = (
  URLMedias: string,
  isDownload: boolean = false,
): ILaunchExternalServiceAction => ({
  payload: { URLMedias, isDownload },
  type: ActionExternalServicesTypeKeys.LAUNCH_EXTERNAL_SERVICE,
});

type LaunchExternalServiceActionPayload = {
  platform: Platform;
  target: string;
  URLPage?: string;
  contentID?: string;
  URLWebsite?: string;
  trackingContext?: ApiV2Context;
  serviceID?: string;
  offerLocation?: OfferLocation;
};
export interface IHandleLaunchExternalServiceAction {
  readonly type: typeof ActionExternalServicesTypeKeys.HANDLE_LAUNCH_EXTERNAL_SERVICE;
  readonly payload: LaunchExternalServiceActionPayload;
}

export const handleLaunchExternalService = ({
  URLPage,
  contentID,
  URLWebsite,
  trackingContext,
  serviceID,
  target,
  platform,
  offerLocation,
}: LaunchExternalServiceActionPayload): IHandleLaunchExternalServiceAction => ({
  payload: {
    platform,
    URLPage,
    contentID,
    URLWebsite,
    trackingContext,
    serviceID,
    target,
    offerLocation,
  },
  type: ActionExternalServicesTypeKeys.HANDLE_LAUNCH_EXTERNAL_SERVICE,
});

export type ExternalServiceActionTypes =
  | ILaunchExternalServiceAction
  | IHandleLaunchExternalServiceAction;
