import {
  CanalBoxOfferLocation,
  CanalPlusOfferLocation,
  TimVisionOfferLocation,
} from './offerLocation';
import type { OfferZoneByLang } from './offerZone';
import { OfferZone } from './offerZone';

/** List of Canal+ {@link @dce-front/sdk-core!OfferZone} by {@link @dce-front/sdk-core!OfferLocation} */
export const canalPlusOfferZoneList: Record<
  CanalPlusOfferLocation,
  OfferZoneByLang
> = {
  [CanalPlusOfferLocation.ad]: { default: OfferZone.cpfra },
  [CanalPlusOfferLocation.au]: { default: OfferZone.cpncl },
  [CanalPlusOfferLocation.at]: { default: OfferZone.toDefine },
  [CanalPlusOfferLocation.bf]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.bi]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.bj]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.bl]: { default: OfferZone.cpant },
  [CanalPlusOfferLocation.cd]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.cf]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.cg]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.ch]: {
    default: OfferZone.cpche,
    de: OfferZone.cpchd,
  },
  [CanalPlusOfferLocation.ci]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.cm]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.cv]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.cz]: { default: OfferZone.toDefine },
  [CanalPlusOfferLocation.dj]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.do]: { default: OfferZone.cpant },
  [CanalPlusOfferLocation.en]: { default: OfferZone.cpeng },
  [CanalPlusOfferLocation.et]: { default: OfferZone.cpeth },
  [CanalPlusOfferLocation.fr]: { default: OfferZone.cpfra },
  [CanalPlusOfferLocation.ga]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.gf]: { default: OfferZone.cpant },
  [CanalPlusOfferLocation.gh]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.gm]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.gn]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.gp]: { default: OfferZone.cpant },
  [CanalPlusOfferLocation.gq]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.gw]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.ht]: { default: OfferZone.cpant },
  [CanalPlusOfferLocation.km]: { default: OfferZone.cpreu },
  [CanalPlusOfferLocation.mc]: { default: OfferZone.cpfra },
  [CanalPlusOfferLocation.mf]: { default: OfferZone.cpant },
  [CanalPlusOfferLocation.mg]: { default: OfferZone.cpmdg },
  [CanalPlusOfferLocation.ml]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.mq]: { default: OfferZone.cpant },
  [CanalPlusOfferLocation.mr]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.mu]: { default: OfferZone.cpmus },
  [CanalPlusOfferLocation.nc]: { default: OfferZone.cpncl },
  [CanalPlusOfferLocation.ne]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.ng]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.ot]: { default: OfferZone.cpoth },
  [CanalPlusOfferLocation.pf]: { default: OfferZone.cppyf },
  [CanalPlusOfferLocation.pl]: { default: OfferZone.cppol },
  [CanalPlusOfferLocation.re]: { default: OfferZone.cpreu },
  [CanalPlusOfferLocation.rw]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.sk]: { default: OfferZone.toDefine },
  [CanalPlusOfferLocation.sl]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.sn]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.td]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.tg]: { default: OfferZone.cpafr },
  [CanalPlusOfferLocation.tt]: { default: OfferZone.cpant },
  [CanalPlusOfferLocation.vu]: { default: OfferZone.cpncl },
  [CanalPlusOfferLocation.wf]: { default: OfferZone.cpncl },
  [CanalPlusOfferLocation.yt]: { default: OfferZone.cpreu },
  [CanalPlusOfferLocation.nl]: { default: OfferZone.toDefine },
};

/** List of CanalBox {@link @dce-front/sdk-core!OfferZone} by {@link @dce-front/sdk-core!OfferLocation} */
export const canalBoxOfferZoneList: Record<
  CanalBoxOfferLocation,
  OfferZoneByLang
> = {
  [CanalBoxOfferLocation.gp]: { default: OfferZone.cbant },
  [CanalBoxOfferLocation.gf]: { default: OfferZone.cbant },
  [CanalBoxOfferLocation.mq]: { default: OfferZone.cbant },
  [CanalBoxOfferLocation.bl]: { default: OfferZone.cbant },
  [CanalBoxOfferLocation.mf]: { default: OfferZone.cbant },
  [CanalBoxOfferLocation.re]: { default: OfferZone.cbreu },
};

/** List of TimVision {@link @dce-front/sdk-core!OfferZone} by {@link @dce-front/sdk-core!OfferLocation} */
export const timVisionOfferZoneList: Record<
  TimVisionOfferLocation,
  OfferZoneByLang
> = {
  [TimVisionOfferLocation.it]: { default: OfferZone.tiita },
};
